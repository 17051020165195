import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

// import { CustomSpinner } from "../../components/custom_spinner";
import {Link} from "@nextui-org/react";
import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip, Snippet} from "@nextui-org/react";
import {
    Table,
    TableHeader,
    TableBody,
    TableColumn,
    TableRow,
    TableCell
} from "@nextui-org/table";
import {Divider} from "@nextui-org/divider";
import {Accordion, AccordionItem} from "@nextui-org/accordion";

import { CustomNavbar } from "../components/navbar";

// import Marquee from "react-fast-marquee";

export function Index() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // useEffect(() => {
    // }, []);

    return (
        <>
            <CustomNavbar />

            <div className="p-6 mx-auto max-w-5xl">
                <div className="w-full flex flex-col items-start">
                    <Snippet className="w-full" symbol="Contract Address:" codeString="0xaDf734e8d910D01E6528240898d895af6c22E2dE">
                        <span className="whitespace-pre-wrap break-words break-all text-xs text-default-500">
                            {`\n0xaDf734e8d910D01E6528240898d895af6c22E2dE`}
                        </span>
                    </Snippet>

                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                        <Card className="">
                            <CardHeader>
                                <p>Buy</p>
                            </CardHeader>
                            <CardBody className="gap-2">
                                <Button as={Link} size="sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://app.uniswap.org/swap?outputCurrency=0xadf734e8d910d01e6528240898d895af6c22e2de&chain=base">Uniswap</Button>
                                <Button as={Link} size="sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://swap.defillama.com/?chain=base&from=0x0000000000000000000000000000000000000000&to=0xadf734e8d910d01e6528240898d895af6c22e2de">LlamaSwap</Button>                                
                            </CardBody>
                        </Card>

                        <Card className="">
                            <CardHeader>
                                <p>Info</p>
                            </CardHeader>
                            <CardBody className="grid grid-cols-2 gap-2">
                                <Button as={Link} size="sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://linktr.ee/WandBot">Linktree</Button>
                                <Button as={Link} size="sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.wandbot.app/">Documentation</Button>
                                <Button as={Link} size="sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://basescan.org/address/0xadf734e8d910d01e6528240898d895af6c22e2de">Basescan</Button>
                                <Button as={Link} size="sm" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://dexscreener.com/base/0x114926eb242c0712c9ab2563bef3c987796d0c68">Dexscreener</Button>                       
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>

            {/* <div className='relative mt-28'>
                <div className="fixed inset-x-0 bottom-0 z-50">
                    <Marquee pauseOnHover>
                        <div className="space-x-10 bg-yellow-500 text-black">
                            {Array.from({ length: 5 }).map((_, index) => (
                                <span key={index}>
                                    wandbot.app under construction due to 
                                    <Link className="mx-1" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://twitter.com/base">@base</Link> 
                                    migration. New website coming soon...
                                </span>
                            ))}
                        </div>
                    </Marquee> 
                </div>
            </div> */}
        </>
    )
}

