import React, { useState, useEffect, useRef } from "react";

import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Tooltip, CheckboxGroup, Checkbox, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Link, Chip, RadioGroup, Radio} from "@nextui-org/react";

import { useAuth } from "./context/auth_context";
import { useAlert } from "./context/alert_context";

// import { TelegramLoginWidgetTest } from "./telegram_login_widget_test";
import { apiEndpointInfo } from "./constants/api_endpoint_info";

export function Subscription() {   
    const { address, isConnected, handleBuyClick, purchaseLoading } = useAuth();
    const { showAlert } = useAlert();

    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const list = [
        {
            subscription_title: "1 Month",
            subscription: "1",
            img: "/subscription_card_image.png",
            price_title: "$500",
            price: "500",
        },
    ];

    // useEffect(() => {
    //     console.log(subscriptionState);
    // }, [subscriptionState]);
    
    return (
        <>
            {/* <div className="gap-4 grid grid-cols-1 sm:grid-cols-3">
                {list.map((item, index) => (
                    <Card 
                        shadow="sm" 
                        key={index} 
                        // isPressable 
                        // onClick={onOpen}
                        // isFooterBlurred
                        className="h-[140px] sm:flex-grow"
                        style={{ backgroundImage: `url(${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                        >
                        <CardHeader className="flex flex-col items-start absolute">
                            <h4 className="text-white font-medium text-5xl">{item.subscription_title}</h4>
                            <p className="text-default-500 text-2xl">
                                {item.price_title}
                            </p>
                        </CardHeader>
                        <CardBody>
                        </CardBody>
                        <CardFooter>
                            <Button 
                                size="sm" 
                                onClick={() => handleBuyClick(item.subscription, item.price)}
                                isDisabled={purchaseLoading}
                            >
                                {purchaseLoading ? "Loading..." : "Purchase"}
                            </Button>
                        </CardFooter>
                    </Card>
                ))}
            </div>  */}

            <Card style={{ backgroundImage: `url(${'/subscription_card_image.png'})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <CardHeader className="flex flex-col items-start">
                    <h4 className="text-white font-medium text-5xl">1 Month</h4>
                    <p className="text-default-500 text-2xl">
                        $500
                    </p>
                </CardHeader>
                <CardBody>
                </CardBody>
                <CardFooter>
                    <Button 
                        size="sm" 
                        onClick={() => handleBuyClick(1, 500)}
                        isDisabled={purchaseLoading}
                    >
                        {purchaseLoading ? "Loading..." : "Purchase"}
                    </Button>
                </CardFooter>
            </Card>
        </>
    );
}