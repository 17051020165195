import React from "react";
import { Image, Link } from "@nextui-org/react";

export function CustomLogo() {   
    return (
        <>
            <Link href="/">
                <Image
                    alt="wandbot logo"
                    // height={62}
                    height={100}
                    radius="none"
                    src="/WandBot.png"
                    // width={62}
                    width={100}
                />
            </Link>
        </>
    );
}