import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAlert } from './alert_context';

import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount, useSignMessage, useEnsName, useAccountEffect, useDisconnect, useSwitchChain, useSimulateContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { getBalance, switchChain, estimateGas } from '@wagmi/core'
import { parseUnits } from 'viem'

import { createConfig, http } from '@wagmi/core'
import { base } from '@wagmi/core/chains'

const config = createConfig({
    chains: [base],
    transports: {
        [base.id]: http('https://base-mainnet.g.alchemy.com/v2/XqS7kjd5z6HF9NOuW9WblYyTxT5NV-R9'),
    },
})

function truncateTelegram(name) {
    if (!name || name.length <= 15) {
        return name; // return the original name if it's too short to truncate
    }
    return `${name.slice(0, 6)}...${name.slice(-6)}`; // Return the truncated address
}

function truncateAddress(address) {
    if (!address || address.length < 10) {
        return address; // return the original address if it's too short to truncate
    }
    return `${address.slice(0, 6)}...${address.slice(-4)}`; // Return the truncated address
}

function truncateEns(ens) {
    if (!ens || ens.length < 10) {
        return ens; // return the original ens if it's too short to truncate
    }
    
    // Finding the position of '.eth'
    let ethIndex = ens.indexOf('.eth');

    // Extract the part before '.eth' and apply truncation
    let name = ens.slice(0, ethIndex);
    if (name.length <= 8) {
        return ens;
    }
    return `${name.slice(0, 4)}...${name.slice(-4)}.eth`;
}

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    // showAlert(`Failed to switch to the chain: ${result.error.message}`, 'error', 'bottom');
    
    const { address, isConnecting, isDisconnected, isConnected, isReconnected, chain } = useAccount()
    const { open, close } = useWeb3Modal()
    const { disconnect } = useDisconnect()
    const { signMessage } = useSignMessage();

    const BASE_MULTISIG_ADDRESS = '0x10185EF114706109fDE7B70c1a8C9C43D0109599'
    const USDC_ADDRESS = '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
    const USDC_ABI_TRANSFER_FUNCTION = [
        {
            type: 'function',
            name: 'transfer',
            stateMutability: 'payable',
            inputs: [
                { name: 'to', type: 'address' },
                { name: 'value', type: 'uint256' },
            ],
            outputs: [{ type: 'bool' }],
        }
    ]

    // const {data: hash, error: writeError, isPending: writePending, writeContract} = useWriteContract()
    const onError = (error) => {
        if (error?.message.includes("User rejected the request")) {
            // showAlert('User rejected the request.', 'error', 'bottom');
            setPurchaseLoading(false)
        } else {
            showAlert(error.message, 'error', 'bottom');
            setPurchaseLoading(false)
        }
    };
    
    const { data: hash, isPending: writePending, writeContract } = useWriteContract({
        mutation: { onError }
    });

    const { data: transactionData, error: transactionError } = useWaitForTransactionReceipt({
        hash: hash,
        config,
    });

    const [agreedToTerms, setAgreedToTerms] = useState(false)
    const [purchaseLoading, setPurchaseLoading] = useState(false)

    const balanceCheck = async (user_address) => {
        try {
            const result = await getBalance(config, {
                address: user_address,
                token: USDC_ADDRESS,
            });
            const usdc_balance = parseFloat(result.formatted);
            console.log('balance', usdc_balance);
            return usdc_balance;
        } catch (error) {
            console.error("Error fetching balance:", error);
            return 0.0;
        }
    };

    const getRemainingSubscriptions = async (user_address) => {
        try {
            const response = await fetch(`${window.origin}/api/get_remaining_subscriptions`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            // return data.data;
            const max_subscriptions = 50;
            const remaining = max_subscriptions - data.data;
            console.log('[getRemainingSubscriptions]', remaining);
            setSubscriptionsRemaining(remaining);
            return remaining
        } catch (error) {
            console.error("Error fetching remaining subscriptions:", error);
            return null;
        }
    };

    const checkIfUserAlreadyBought = async () => {
        try {
            const response = await fetch(`${window.origin}/api/check_if_user_already_bought`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ wallet_address: address }),
            });
            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error("Error checking subscription status:", error);
            return false;
        }
    };

    useEffect(() => {
        if (transactionData) {
            console.log('[hash]', transactionData);
            let txn_hash = transactionData.transactionHash
            console.log('[txn hash]', txn_hash)
            showAlert('Successfully purchased a monthly subscription!', 'success', 'bottom');
            fetch(`${window.origin}/api/write_subscription_purchase`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    wallet_address: address,
                    // receipt: transactionData, // Uncaught TypeError: Do not know how to serialize a BigInt
                    transaction_hash: txn_hash
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.data === 'ok') {
                    console.log('success when writing purchase', data);
                    setPurchaseLoading(false)
                    setSubscriptionsRemaining((previous) => previous - 1)
                } else if (data.data === 'error') {
                    const message = data.message || 'An unknown error occurred';
                    console.log('Error', data);
                    setPurchaseLoading(false);
                    if (message) {
                        console.error('Error message:', message);
                        showAlert(message, 'error', 'bottom')
                    }
                }
            })
            .catch((error) => {
                console.log("Fetch error: ", error.message);
                setPurchaseLoading(false)
            });
        }
    
        if (transactionError) {
            setPurchaseLoading(false);
            showAlert(`Error: ${transactionError.message}`, 'error', 'bottom');
        }
    }, [transactionData, transactionError]);

    const handleBuyClick = async (subscription, price) => {
        setPurchaseLoading(true)
        // const testPrice = 0.01;
        // price = testPrice;
        console.log('buying', subscription, price);

        let usdc_balance = await balanceCheck(address);
        if (usdc_balance < price) {
            console.log("can't buy: not enough usdc");
            showAlert("Insufficient USDC balance", 'error', 'bottom');
            setPurchaseLoading(false);
            return;
        }

        let alreadyBought = await checkIfUserAlreadyBought();
        if (alreadyBought) {
            console.log("can't buy: already bought a subscription for this month");
            showAlert("Subscription already purchased", 'error', 'bottom');
            setPurchaseLoading(false);
            return;
        }

        let remainingSubscriptions = await getRemainingSubscriptions();
        setSubscriptionsRemaining(remainingSubscriptions)
        if (remainingSubscriptions === null) {
            console.log('error fetching remaining subscriptions')
            return
        }
        if (remainingSubscriptions === 0) {
            console.log("can't buy: no more memberships available");
            showAlert("Subscription limit exceeded, check again next month", 'error', 'bottom');
            setPurchaseLoading(false);
            return;
        }

        console.log('can buy');
        writeContract({
            address: USDC_ADDRESS,
            abi: USDC_ABI_TRANSFER_FUNCTION,
            functionName: 'transfer',
            args: [
                BASE_MULTISIG_ADDRESS,
                parseUnits(price.toString(), 6)
            ]
        })
    };

    useAccountEffect({
        onConnect(data) {
            if (data.isReconnected) {                
                console.log('reconnected', data.address)
            } else {
                console.log('first time connection', data.address)
            }
        }
    })

    const handleOpen = () => {
        try {
            open();
        } catch (error) {
            console.error("Error opening the wallet modal:", error);
        }
    };

    const handleDisconnect = () => {
        try {
            disconnect();
        } catch (error) {
            console.error("Error disconnecting the wallet modal:", error);
        }
    };

    const [subscriptionsRemaining, setSubscriptionsRemaining] = useState(null);
    const [subscriptionsRemainingLoading, setSubscriptionsRemainingLoading] = useState(false);

    const fetchRemainingSubscriptions = async () => {
        try {
            setSubscriptionsRemainingLoading(true)
            const response = await fetch(`${window.origin}/api/get_remaining_subscriptions`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
                setSubscriptionsRemainingLoading(false)
            }
            const data = await response.json();
            console.log('data', data)
            setSubscriptionsRemainingLoading(false)
            return data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setSubscriptionsRemainingLoading(false)
            return null;
        }
    };

    useEffect(() => {
        const updateData = async () => {
            const result = await fetchRemainingSubscriptions();
            if (result && result.data !== undefined) {
                const max_subscriptions = 50;
                const remaining = max_subscriptions - result.data; // Access data correctly
                console.log('remaining', remaining);
                setSubscriptionsRemaining(remaining);
                // setSubscriptionsRemaining(0);
            } else {
                console.log('Invalid result structure', result);
                showAlert('Error fetching remaining subscriptions', 'error', 'bottom')
            }
        };

        // Initial fetch
        updateData();

        // Set up polling
        const intervalId = setInterval(updateData, 10000); // Poll every 10 seconds, adjust as needed

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <AuthContext.Provider value={{ handleOpen, handleDisconnect, address, isConnected, truncateAddress, truncateTelegram, agreedToTerms, setAgreedToTerms, handleBuyClick, subscriptionsRemaining, subscriptionsRemainingLoading, purchaseLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
