import React, { useState, useEffect, useRef } from "react";
import { Link, Button, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Tabs, Tab, Card, CardBody } from "@nextui-org/react";


export function GettingStarted() {   
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    return (
        <>
            <Button size="sm" color="default" onPress={onOpen}>Getting Started</Button>

            <Modal backdrop="blur" isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                Getting Started
                            </ModalHeader>
                            <ModalBody>
                                <div className="space-y-2">
                                    <Card>
                                        <CardBody className="text-default-500">
                                            1️⃣ Connect your wallet and agree to the terms
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody className="text-default-500">
                                            2️⃣ Buy the monthly subscription
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody className="text-default-500">
                                            <span>3️⃣ Authenticate with this wallet on <Link isExternal showAnchorIcon anchorIcon={' ↗'} href="https://ozkey.app">ozkey.app</Link></span>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody className="text-default-500">
                                            4️⃣ You are now a wandlet. Enjoy access to our alerts!
                                        </CardBody>
                                    </Card>
                                </div>
                            </ModalBody>
                            <ModalFooter className="text-default-500">
                                {/* <span className="text-xs">Telegram widget not working? DM <Link className="text-xs" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/OzKeyBot?start=start">@OzKeyBot</Link> to get a unique UUID</span> */}
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}