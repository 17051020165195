import React, { useState, useEffect } from "react";
import { Card, CardBody } from "@nextui-org/react";


export function Alert({ text, type, position }) { 
    const alertClasses = {
        error: { bg: 'bg-red-500', text: 'text-black' },
        success: { bg: 'bg-green-500', text: 'text-black' },
        warning: { bg: 'bg-yellow-500', text: 'text-black' },
        info: { bg: 'bg-blue-500', text: 'text-black' }
    };
    
    const { bg: bgColorClass, text: textColorClass } = alertClasses[type] || { bg: null, text: 'text-white' };

    return (
        <>
            <div className={`fixed ${position === 'top' ? 'top-0' : 'bottom-0'} inset-x-0 mx-auto w-full max-w-md px-6 z-50`}>
                <Card className={`${bgColorClass} ${textColorClass} my-6`}>
                    <CardBody>
                        <p>{text}</p>
                    </CardBody>
                </Card>
            </div>
        </>
    );
}