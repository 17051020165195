import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import {Link} from "@nextui-org/react";
import {Image, Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Tooltip, Dropdown, DropdownMenu, DropdownTrigger, DropdownItem} from "@nextui-org/react";


import { SiGitbook } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import { IoChevronDownSharp } from "react-icons/io5";

import { useAuth } from "./context/auth_context";

// import { Login } from "./login";
import { CustomLogo } from "./logo";

export function CustomNavbar() {
    const { handleOpen, handleDisconnect, address, isConnected, truncateAddress, truncateTelegram, telegramUserData, apiAccountData, signedIn, signedInLoading, handleSignIn, handleSignOut } = useAuth();

    // const location = useLocation();
    // const showLogin = location.pathname === '/pricing' || 
    //     location.pathname === '/dashboard' ||
    //     location.pathname === '/playground'

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
                <NavbarContent>
                    <NavbarMenuToggle
                        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                        className="sm:hidden px-4"
                    />

                    <NavbarBrand>
                        {isMobile ? (
                            <CustomLogo />
                        ) : (
                            <Link className="font-bold text-inherit" href="/">WandBot</Link>
                        )}
                    </NavbarBrand>
                </NavbarContent>

                <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarItem>
                        <Link color="foreground" href="/subscribe">Subscribe</Link>
                    </NavbarItem>
                </NavbarContent>

                <NavbarContent justify="end">
                    <div className="hidden sm:flex gap-1">
                        <Tooltip content="Twitter">
                            <NavbarItem>
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://twitter.com/wand_bot" isExternal={true} isIconOnly aria-label="Twitter">
                                        <FaXTwitter />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip>

                        <Tooltip content="Telegram">
                            <NavbarItem>
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://t.me/+vhLia5ksuYYzNGJh" isExternal={true} isIconOnly aria-label="Telegram">
                                        <FaTelegramPlane />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip>

                        <Tooltip content="Gitbook">
                            <NavbarItem>
                                    <Button color="default" variant="flat" size="sm" as={Link} href="https://docs.wandbot.app/" isExternal={true} isIconOnly aria-label="Gitbook">
                                        <SiGitbook />
                                    </Button> 
                            </NavbarItem>
                        </Tooltip>
                    </div>

                    {/* {showLogin && (
                        <>
                            <NavbarItem>
                                <Login />
                            </NavbarItem>
                        </>
                    )} */}
                </NavbarContent>

                <NavbarMenu className="z-50">
                    <div className="mb-4">
                        <p>Services</p>

                        <NavbarMenuItem>
                            <Link className="text-default-500" href="/subscribe" size="lg">Subscribe</Link>
                        </NavbarMenuItem>
                    </div>

                    <div>
                        <p>Socials</p>

                        <NavbarMenuItem>
                            <Link className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://twitter.com/wand_bot">Twitter</Link>
                        </NavbarMenuItem>

                        <NavbarMenuItem>
                            <Link className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://t.me/+vhLia5ksuYYzNGJh">Telegram</Link>
                        </NavbarMenuItem>

                        <NavbarMenuItem>
                            <Link className="text-default-500" isExternal showAnchorIcon anchorIcon={' ↗'} href="https://docs.wandbot.app/">Gitbook</Link>
                        </NavbarMenuItem>
                    </div>
                </NavbarMenu>
            </Navbar>
        </>
    );
}