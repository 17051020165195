import React, { createContext, useContext, useCallback, useState, useRef } from "react";
import { Alert } from "../alert";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState(null);
    const timeoutRef = useRef(null);

    const showAlert = useCallback((text, type, position) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        setAlert({ text, type, position });

        timeoutRef.current = setTimeout(() => {
            setAlert(null);
            timeoutRef.current = null;
        }, 3000);
    }, []);

    return (
        <AlertContext.Provider value={{ showAlert }}>
            {children}
            {alert && <Alert {...alert} />}
        </AlertContext.Provider>
    );
};
