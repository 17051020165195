import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
// import {Link} from "@nextui-org/react";
import {Tabs, Tab, Card, CardHeader, CardBody, CardFooter, Image, Button, Tooltip, Link, Spinner, CheckboxGroup, Checkbox} from "@nextui-org/react";
import {
    Modal, 
    ModalContent, 
    ModalHeader, 
    ModalBody, 
    ModalFooter,
    useDisclosure
} from "@nextui-org/react";
import { CustomNavbar } from "../components/navbar";

import { useAuth } from "../components/context/auth_context";

import { Subscription } from "../components/subscription_package";
import { GettingStarted } from "../components/getting_started";

export function Subscribe() {
    const { handleOpen, handleDisconnect, signMessage, isConnected, isDisconnected, address, truncateAddress, agreedToTerms, setAgreedToTerms, subscriptionsRemaining, subscriptionsRemainingLoading } = useAuth();
    
    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    // useEffect(() => {
    //     if (!uuid) {
    //         console.log('no uuid');
    //         return;
    //     } else {
    //         console.log('found uuid', uuid)
    //         // setUuid(uuid);
    //     }
    // }, [uuid]);

    const handleTerms = () => {
        setAgreedToTerms(true)
        onOpenChange(false)
    }

    const getColorClass = (remaining) => {
        if (remaining === 0) {
            return 'text-red-500';
        } else if (remaining > 0 && remaining <= 10) {
            return 'text-orange-500';
        } else if (remaining > 10 && remaining <= 20) {
            return 'text-orange-400';
        } else if (remaining > 20 && remaining <= 30) {
            return 'text-yellow-400';
        } else if (remaining > 30 && remaining <= 40) {
            return 'text-yellow-500';
        } else if (remaining > 40 && remaining <= 50) {
            return 'text-green-500';
        } else {
            return 'text-green-500';
        }
    };

    const [featureSelected, setFeatureSelected] = useState(["one", "two", "three"]);
 
    return (
        <>
            <CustomNavbar />

            <div className="p-6 mx-auto max-w-5xl">
                <Card className="bg-yellow-500 text-black">
                    <CardHeader className=" flex flex-col justify-start items-start ">
                        <span className="font-bold">
                            Important!
                        </span>

                        <p>
                            Please note that we currently only accept <span className="font-bold">USDC</span> via onchain payments through the Base blockchain.
                        </p>
                    </CardHeader>
                </Card>

                <div className="mt-4">
                    <div className="flex flex-col items-start sm:items-center mx-auto">
                        <p className="text-5xl">Monthly Subscription</p>
                        <p className="text-default-500">
                            Not ready to commit long-term? Secure a spot with our monthly membership today!
                        </p>

                        <div className="mt-2">
                            <GettingStarted />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-28">
                        <div className="flex flex-col space-y-4">
                            <Card className="w-full flex-grow">
                                {/* <CardHeader>Info</CardHeader> */}
                                <CardBody>
                                    <p className="text-2xl sm:text-3xl">
                                        Spots Remaining: <span className={subscriptionsRemainingLoading ? '' : getColorClass(subscriptionsRemaining)}>
                                            {subscriptionsRemainingLoading ? (
                                                <Spinner size="sm" />
                                            ) : (
                                                subscriptionsRemaining !== null ? subscriptionsRemaining : 0
                                            )}
                                            <span>/50</span>
                                        </span>
                                    </p>
                                    <p className="text-2xl sm:text-3xl mt-4">Current Price: <span className="text-green-500">$500</span></p>
                                </CardBody>
                            </Card>

                            <Card className="w-full flex-grow">
                                {/* <CardHeader>Features</CardHeader> */}
                                <CardBody>
                                    <CheckboxGroup
                                        // label="Features"
                                        // defaultValue={["one", "two", "three"]}
                                        value={featureSelected}
                                        onValueChange={setFeatureSelected}
                                        // className="mt-10"
                                    >
                                        <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="one">Access to WandBot alerts</Checkbox>
                                        <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="two">Access to WandBot research tooling</Checkbox>
                                        <Checkbox color="success" defaultSelected isSelected isReadOnly className="cursor-default" value="three">Try before you commit</Checkbox>
                                    </CheckboxGroup>
                                </CardBody>
                            </Card> 
                        </div>

                        <div className="flex flex-col space-y-4">
                            <Card className="w-full flex-grow">
                                <CardHeader className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                                    {isConnected ? (
                                        <>
                                            <p className="text-default-500">Connected as: <span className="text-green-500">{truncateAddress(address)}</span></p>
                                            <Button onClick={handleDisconnect} color="default" size="sm">Disconnect</Button>
                                        </>
                                    ) : null}

                                    <div className="flex space-x-4 items-start">
                                        {!isConnected && (                                        
                                            <Button onClick={handleOpen} color="primary" size="sm">Connect Wallet</Button>
                                        )}

                                        {!agreedToTerms ? (
                                            <Button onClick={onOpen} color="primary" size="sm">Agree to Terms</Button>
                                        ) : null}
                                    </div>
                                </CardHeader>
                            </Card>

                            {subscriptionsRemaining === 0 ? (
                                <>
                                    <Card className="w-full flex-grow">
                                        <CardBody>
                                            <p className=" text-5xl text-red-500">
                                                No spots remaining!
                                            </p>
                                            <p className="text-xl text-default-500">Please come back next month</p>
                                        </CardBody>
                                    </Card>
                                </>
                            ) : (
                                <div className="w-full">
                                    {isConnected && address && agreedToTerms ? (                              
                                        <Subscription />
                                    ) : (
                                        <Card className="w-full flex-grow">
                                            <CardBody>
                                                <p className="text-red-500">Please Connect your wallet and accept the terms before continuing.</p>
                                            </CardBody>
                                        </Card>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={isOpen} onOpenChange={onOpenChange} backdrop="blur">
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">WandBot Subscription Terms</ModalHeader>
                            <ModalBody>
                                <div className="">
                                    <p>1. There are no refunds.</p>
                                    <p>2. You must buy the membership through this interface</p>
                                    <p>3. We reserve the right to change the terms, namely the pricing and subscription length *</p>
                                    <p className="text-xs text-default-500">* Memberships that have already been bought will not be changed</p>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="w-full" color="primary" onClick={handleTerms}>
                                    I Agree
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}